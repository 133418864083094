import React from 'react'
import {
  ButtonProps,
  ElementProps,
  Button as MantineButton,
} from '@mantine/core'

import styles from './Button.module.scss'

export interface LinkButtonProps
  extends ButtonProps,
    ElementProps<'a', keyof ButtonProps> {
  href: string
}

export const LinkButton = ({
  variant = 'default',
  href,
  disabled,
  ...props
}: LinkButtonProps) => {
  return (
    <MantineButton
      component={'a'}
      href={!disabled ? href : undefined}
      variant={variant}
      disabled={disabled}
      {...props}
      classNames={{
        root: styles.mantineButtonRoot,
      }}
    />
  )
}
