import React from 'react'
import { Modal as MantineModal, type ModalProps } from '@mantine/core'

import { classesMerge } from '@/core/helpers/classes-merge'

import styles from './Modal.module.scss'

export const Modal = ({ classNames, ...props }: ModalProps) => {
  return (
    <MantineModal
      classNames={{
        ...classNames,
        title: classesMerge(
          styles.modalTitle,
          classNames && 'title' in classNames ? classNames.title : '',
        ),
        header: classesMerge(
          styles.modalHeader,
          classNames && 'header' in classNames ? classNames.header : '',
        ),
        body: classesMerge(
          styles.modalBody,
          classNames && 'body' in classNames ? classNames.body : '',
        ),
      }}
      {...props}
    />
  )
}
