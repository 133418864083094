import { ReactNode } from 'react'
import { z } from 'zod'

export const notificationSchema = z.object({
  uuid: z.string().uuid(),
  notification_type: z.enum(['default', 'default_with_icons']),
  category: z.enum(['info', 'success', 'warning', 'error']),
  action_link: z
    .object({
      label: z.string().max(20),
      url: z.string().url(),
    })
    .nullable(),
  title: z.string(),
  description: z.string().or(z.custom<ReactNode>()).nullable(),
  is_read: z.boolean(),
  created_at: z.string(),
  expired_at: z.string(),
})

export const pushNotificationSchema = notificationSchema.extend({
  uuid: z.string().optional(),
  notification_type: notificationSchema.shape.notification_type.optional(),
  action_link: notificationSchema.shape.action_link.optional(),
  is_read: notificationSchema.shape.is_read.optional(),
  autoclose: z.union([z.boolean(), z.number()]).nullable(),
  created_at: notificationSchema.shape.created_at.optional(),
  expired_at: notificationSchema.shape.expired_at.optional(),
})

export type Notification = z.infer<typeof notificationSchema>
export type PushNotification = z.infer<typeof pushNotificationSchema>
