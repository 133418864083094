import { config } from '../config'
import { DefaultLocalDataManager } from '../managers/DefaultLocalDataManager'
import SymmetricKeyManager from '../managers/SymmetricKeyManager'

import { encryptSymmetrically } from './DefaultCryptographyService'

export const encryptData = async (data: unknown) => {
  const ldm = new DefaultLocalDataManager(config.DB_NAME, config.DB_VERSION)
  const skm = new SymmetricKeyManager(ldm)
  const key = await skm.get()
  const encryptedData = await encryptSymmetrically(key, JSON.stringify(data))

  return encryptedData
}
