import { encryptData } from './encryptData'

export const getUpcomingSessions = async () => {
  try {
    const response = await fetch(
      new URL(
        `${window.location.origin}/api/sessions/v1/sessions/upcoming/?range=week`,
      ),
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRFToken': window.CSRFTOKEN,
          Accept: 'application/json',
        },
      },
    )
    if (!response.ok) {
      throw new Error(`${(response.status, response.statusText)}`)
    } else {
      const upcomingSessions = await response.json()
      const encryptedUpcomingSessions = await encryptData(upcomingSessions)
      const headers = {
        'Content-Type': 'text/plain',
        'Content-Length': encryptedUpcomingSessions.length.toString(),
        'Response-Type': 'basic',
        Vary: 'Accept-Language, Cookie',
      }
      const upcomingSessionsResponse = new Response(encryptedUpcomingSessions, {
        status: 200,
        headers,
      })
      const cacheTwyllOffline = await caches.open('twyll-offline')

      await cacheTwyllOffline.put(
        '/sessions/upcoming/',
        upcomingSessionsResponse,
      )

      return upcomingSessions
    }
  } catch (e) {
    if (e instanceof Error) {
      const errorMessage = e instanceof Error ? e.message : e
      throw new Error(`Failed to fetch upcoming sessions: ${errorMessage}`, {
        cause: e,
      })
    }
  }
}
