import { Dispatch, SetStateAction } from 'react'

import {
  SYNC_EVENT_TYPES,
  SyncEventBusManagerInterface,
} from '../interfaces/SyncEventBusManagerInterface'
import { WebSocketManagerInterface } from '../interfaces/WebSocketManagerInterface'
import { HeartbeatManagerInterface } from '../interfaces/HeartbeatManagerInterface'

import SyncEventBusManager from './SyncEventBusManager'

const SESSION_SOCKET_PING_INTERVAL = 4000

/**
 * DBW 10/9/24: Rather than use window.addEventListener('online', ...) and
 * window.addEventListener('offline', ...), we use the heartbeatManager to
 * observe the online/offline state. This is because the heartbeatManager is
 * more robust and can handle cases where the client has virtualization software
 * or network adapters that prevent the browser from detecting when it has gone
 * offline (the most common case is a VPN, which especially causes trouble for
 * us internally when testing in support and staging, which are only accessible
 * via VPN, and which is common for our end users as well). There seem to be
 * differences in browser implementations for navigator.onLine, and while Safari
 * is the worst offender, in order to make the implementation easier to
 * understand for developers and more predictable for end users, we reduce
 * complexity by always using the heartbeat manager, and never using the navigator
 * to determine whether the application is offline or not.
 */
class HeartbeatManager implements HeartbeatManagerInterface {
  private _observers: Dispatch<SetStateAction<boolean>>[] = []
  private _isOnline = navigator.onLine
  private _webSocketManagers: WebSocketManagerInterface[] = []
  private _syncEventBusManager: SyncEventBusManagerInterface =
    new SyncEventBusManager()

  constructor() {
    this._isOnline = true
    setInterval(() => {
      this.ping()
    }, 5000)
  }

  observe(update: Dispatch<SetStateAction<boolean>>) {
    this._observers.push(update)
    update(this._isOnline)
  }

  get isOnline(): boolean {
    try {
      if (
        this._webSocketManagers.every(
          (manager) => manager.readyState === WebSocket.OPEN,
        )
      ) {
        return this._isOnline
      } else {
        return false
      }
    } catch (err) {
      console.error(err)
      return false
    }
  }

  removeObserver(update: Dispatch<SetStateAction<boolean>>) {
    this._observers = this._observers.filter((observer) => observer !== update)
  }

  registerWebSocket(webSocketManager: WebSocketManagerInterface) {
    this._webSocketManagers.push(webSocketManager)
  }

  unregisterWebSocket(webSocketManager: WebSocketManagerInterface): void {
    this._webSocketManagers = this._webSocketManagers.filter(
      (manager) => manager !== webSocketManager,
    )
  }

  private setIsOnline(newIsOnline: boolean) {
    if (newIsOnline !== this._isOnline) {
      this._observers.forEach((update) => update(newIsOnline))
      this._isOnline = newIsOnline

      if (this._isOnline) {
        this._syncEventBusManager.dispatch(
          new CustomEvent(SYNC_EVENT_TYPES.RECONNECT),
        )
      } else {
        this._syncEventBusManager.dispatch(
          new CustomEvent(SYNC_EVENT_TYPES.DISCONNECT),
        )
      }
    }

    if (newIsOnline) {
      this._syncEventBusManager.dispatch(
        new CustomEvent(SYNC_EVENT_TYPES.PING_SUCCESS),
      )
    }
  }

  private ping() {
    // DBW 8/1/2024: We get false positives, but not false negatives, from navigator.onLine,
    // per MDN, so we can safely bomb out when navigator.onLine is false.
    if (!navigator.onLine) {
      this.setIsOnline(false)
      return
    }

    const timeout = new Promise((resolve) => {
      setTimeout(resolve, SESSION_SOCKET_PING_INTERVAL)
    })
    Promise.race([
      Promise.all([
        ...this._webSocketManagers.map((webSocket) =>
          webSocket
            .sendMessage({ event_type: 'ping' })
            .then(() => {
              return false
            })
            .catch((e) => {
              if (
                e.message ===
                'sendMessage called with socket in closing or closed state'
              ) {
                return false
              }

              return true
            }),
        ),
        fetch('/ping/')
          .then(() => {
            return false
          })
          .catch(() => {
            return true
          }),
      ]),
      timeout.then(() => {
        return true
      }),
    ]).then((didTimeout) => {
      if (Array.isArray(didTimeout)) {
        didTimeout = didTimeout.reduce((a, b) => a || b, false)
      }
      this.setIsOnline(!didTimeout)
    })
  }
}

export default HeartbeatManager
